import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
import Chats from "@/pages/Chats.vue";
import Customers from "@/pages/Customers.vue";
import Agents from "@/pages/Agents.vue";
import Tickets from "@/pages/Tickets.vue";
import TicketsNew from "@/pages/Tickets/New.vue";
import TicketsView from "@/pages/Tickets/View.vue";
import Websites from "@/pages/Websites.vue";
import Settings from "@/pages/Settings.vue";
import SettingsOrganization from "@/pages/Settings/Organization.vue";
import SettingsTickets from "@/pages/Settings/Tickets.vue";
import Login from "@/pages/Login.vue";
import Logout from "@/pages/Logout.vue";

import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Typography from "@/pages/Typography.vue";
import TableList from "@/pages/TableList.vue";

const routes = [
    {
        path: "/",
        component: DashboardLayout,
        redirect: "/chats",
        children: [
            {
                path: "/chats",
                name: "chats",
                component: Chats,
                meta: {
                    protected: true
                }
            },
            {
                path: "/tickets",
                name: "tickets",
                component: Tickets,
                meta: {
                    protected: true
                }
            },
            {
                path: "/tickets/new/:message",
                name: "New Ticket",
                component: TicketsNew,
                meta: {
                    protected: true
                }
            },
            {
                path: "/tickets/view/:id",
                name: "View Ticket",
                component: TicketsView,
                meta: {
                    protected: true
                }
            },
            {
                path: "/customers",
                name: "customers",
                component: Customers,
                meta: {
                    protected: true
                }
            },
            {
                path: "/agents",
                name: "agents",
                component: Agents,
                meta: {
                    protected: true
                }
            },
            {
                path: "/websites",
                name: "websites",
                component: Websites,
                meta: {
                    protected: true
                }
            },
            {
                path: "/settings",
                component: Settings,
                redirect: "/settings/organization",
                children: [
                    {
                        path: "/settings/organization",
                        name: "Organization Settings",
                        component: SettingsOrganization,
                        meta: {
                            protected: true
                        }
                    },
                    {
                        path: "/settings/tickets",
                        name: "Ticket Settings",
                        component: SettingsTickets,
                        meta: {
                            protected: true
                        }
                    }
                ]
            },
            {
                path: "icons",
                name: "icons",
                component: Icons
            },
            {
                path: "maps",
                name: "maps",
                component: Maps
            },
            {
                path: "typography",
                name: "typography",
                component: Typography
            },
            {
                path: "table-list",
                name: "table-list",
                component: TableList
            }
        ]
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout
    },
    { path: "*", component: NotFound }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
