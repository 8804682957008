<template>
    <b-container>
        <b-row class="border-bottom p-2" v-for="(ticket, index) in tickets" :key="index">
            <b-col cols="3">
                <b-row>
                    <b-col>
                        <router-link
                            :to="`/tickets/view/${ticket.ticketId}`"
                            class="stretched-link"
                        >
                            <i class="las la-hashtag mr-1"></i>
                            <span>{{ ticket.ticketId }}</span>
                        </router-link>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-truncate">
                        <i class="las la-users-cog mr-1"></i>
                        <span>{{ ticket.groupName }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-truncate">
                        <i class="las la-calendar mr-1"></i>
                        <span>{{ ticket.dateCreated }}</span>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="4">
                <b-row>
                    <b-col>
                        <i class="las la-comment-alt mr-1"></i>
                        <b>{{ ticket.subject }}</b>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <i class="las la-folder-open mr-1"></i>
                        <span>{{ ticket.categoryName }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-truncate">
                        <i class="las la-calendar-check mr-1"></i>
                        <span>{{ ticket.dateUpdated }}</span>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="3" class>
                <b-row>
                    <b-col class="text-truncate">
                        <i class="las la-address-book mr-1"></i>
                        <span>{{ ticket.customerName }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-truncate">
                        <i class="las la-user-tag mr-1"></i>
                        <span>{{ ticket.agentName }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-truncate" v-if="ticket.status !== 3 && ticket.status !== 4">
                        <i class="las la-hourglass-half mr-1"></i>
                        <span>Ongoing for {{ parseFloat(ticket.duration).toFixed(2) }}</span>
                    </b-col>
                    <b-col class="text-truncate" v-else>
                        <i class="las la-hourglass-end mr-1"></i>
                        <span>Took {{ parseFloat(ticket.resolutionLength).toFixed(2) }}</span>
                    </b-col>
                </b-row>
            </b-col>

            <b-col cols="2" class="text-center">
                <b-row>
                    <b-col class="text-truncate">
                        <i class="las la-exclamation text-danger mr-1" v-show="ticket.overdue"></i>
                        <span>{{ ticket.priorityName }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-truncate">
                        <span
                            class="badge"
                            v-bind:class="{
								'badge-danger': ticket.status === 1,
								'badge-primary': ticket.status === 2,
								'badge-warning': ticket.status === 3,
								'badge-success': ticket.status === 4
							}"
                        >{{ ticket.statusName }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-truncate" v-if="ticket.status !== 3 && ticket.status !== 4">
                        <i class="las la-stopwatch mr-1"></i>
                        <span>{{ parseFloat(ticket.remaining).toFixed(2) }} left</span>
                    </b-col>
                    <b-col class="text-truncate" v-else>
                        <i class="las la-clock mr-1"></i>
                        <span>{{ parseFloat(ticket.saved).toFixed(2) }} saved</span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: ["tickets"],
    computed: {
        ...mapGetters({
            user: "user"
        })
    }
};
</script>
