<template>
    <div>
        <div class="row">
            <div class="col-12">
                <card class="pt-1">
                    <b-row class="border-bottom">
                        <b-col>
                            <b-nav pills align="right" class="pb-3">
                                <b-nav-form v-on:submit.prevent class="mr-1">
                                    <button
                                        class="mt-1 mr-1 btn btn-primary"
                                        v-bind:class="{
							'active':
								filters.agent === null
						}"
                                        @click="filters.agent = null"
                                    >All Tickets</button>
                                </b-nav-form>
                                <b-nav-form v-on:submit.prevent class="mr-1">
                                    <button
                                        class="mt-1 mr-1 btn btn-primary"
                                        v-bind:class="{
							'active':
								filters.agent === user.uid
						}"
                                        @click="filters.agent = user.uid"
                                    >My Tickets</button>
                                </b-nav-form>
                                <b-nav-form class="mr-1">
                                    <b-form-select
                                        placeholder="Filter by status"
                                        v-model="filters.status"
                                        :options="status"
                                    ></b-form-select>
                                </b-nav-form>
                                <b-nav-form class="mr-1" v-if="user.master">
                                    <b-form-select
                                        placeholder="Filter by group"
                                        v-model="filters.group"
                                        :options="groups"
                                    ></b-form-select>
                                </b-nav-form>
                                <b-nav-form class="mr-1">
                                    <b-form-select
                                        placeholder="Filter by category"
                                        v-model="filters.category"
                                        :options="categories"
                                    ></b-form-select>
                                </b-nav-form>
                                <b-nav-form class="mr-1">
                                    <b-form-select
                                        placeholder="Filter by priority"
                                        v-model="filters.priority"
                                        :options="priorities"
                                    ></b-form-select>
                                </b-nav-form>
                                <b-nav-form>
                                    <b-form-input
                                        aria-label="Input"
                                        class="mr-1"
                                        placeholder="Search here"
                                        v-model="filters.term"
                                    ></b-form-input>
                                </b-nav-form>
                            </b-nav>
                        </b-col>
                    </b-row>
                    <b-row>
                        <List :tickets="hits" v-if="ready" />
                        <div
                            v-else
                            class="col-12 d-flex flex-row justify-content-center align-items-center"
                            style="height: 300px;"
                        >
                            <img src="/img/spinner.gif" height="100" alt />
                        </div>
                    </b-row>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import List from "./Tickets/List.vue";
import { api } from "../config";
import moment from "moment";
import { mapGetters } from "vuex";
import search from "@/libraries/Search";
export default {
    data: () => ({
        status: [
            { text: "Filter by status", value: null },
            { text: "Open", value: 1 },
            { text: "Pending", value: 2 },
            { text: "Spam", value: 3 },
            { text: "Solved", value: 4 }
        ],
        hits: [],
        pages: [],
        filters: search.setDefaultFilter({
            hitsPerPage: 20,
            page: 1,
            status: null,
            replica: "tickets",
            term: "",
            category: null,
            priority: null,
            group: null,
            agent: null
        }),
        pagination: {
            totalRows: null,
            items: []
        },
        categories: [],
        groups: [],
        priorities: [],
        ready: false
    }),
    computed: {
        searchFilters() {
            let filters = { ...this.filters };
            return search.getURLString(filters);
        },
        ...mapGetters({
            user: "user"
        })
    },
    async mounted() {
        await this.getTickets();
        await this.getTicketCategories();
        await this.getTicketGroups();
        await this.getTicketPriorities();
    },
    methods: {
        async getTickets() {
            this.ready = false;

            let request = await fetch(`${api}/tickets?${this.searchFilters}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let data = await request.json();

            this.hits = data.hits;
            this.hits.map(hit => {
                hit.dateCreated = moment(hit.createdAt).format("MM/DD/YYYY");
                hit.dateUpdated = moment(hit.updatedAt).format("MM/DD/YYYY");
                hit.duration = moment
                    .duration(moment().diff(moment(hit.createdAt)))
                    .asHours();
                hit.overdue =
                    hit.duration > hit.priorityDuration && hit.status !== 4;
                hit.resolutionLength = moment
                    .duration(moment(hit.updatedAt).diff(moment(hit.createdAt)))
                    .asHours();
                let remaining = parseFloat(
                    parseFloat(hit.priorityDuration) - parseFloat(hit.duration)
                ).toFixed(2);
                hit.remaining = remaining > 0 ? remaining : 0;
                hit.saved = parseFloat(
                    parseFloat(hit.priorityDuration) -
                        parseFloat(hit.resolutionLength)
                ).toFixed(2);
            });

            let _tempPageItems = [];
            this.pagination.totalRows = data.nbHits;
            for (let index = 0; index < data.nbPages; index++) {
                _tempPageItems.push(index);
            }
            this.pagination.items = _tempPageItems;

            // let list = [];
            // this.tickets.forEach(ticket => {
            //     if (
            //         this.user.master === 1 ||
            //         this.user.groupName === ticket.groupName
            //     ) {
            //         list.push(ticket);
            //     }
            // });

            // this.tickets = list;
            this.ready = true;
        },
        async getTicketCategories() {
            let request = await fetch(`${api}/tickets/categories`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.categories = [{ text: "Filter by category", value: null }];
            response.forEach(category => {
                this.categories.push({
                    value: category.uid,
                    text: category.name
                });
            });
        },
        async getTicketGroups() {
            let request = await fetch(`${api}/users/groups`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.groups = [{ text: "Filter by group", value: null }];
            response.forEach(group => {
                this.groups.push({
                    value: group.uid,
                    text: group.name
                });
            });
        },
        async getTicketPriorities() {
            let request = await fetch(`${api}/tickets/priorities`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.priorities = [{ text: "Filter by priority", value: null }];
            response.forEach(priority => {
                this.priorities.push({
                    value: priority.uid,
                    text: priority.name
                });
            });
        }
    },
    watch: {
        filters: {
            handler: function(query) {
                search.setURLParameters(query);
                this.getTickets();
            },
            deep: true
        }
    },
    components: {
        List
    }
};
</script>
