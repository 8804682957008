<template>
    <div class="content">
        <div class="container-fluid" v-if="ready">
            <transition name="fade" mode="out-in">
                <!-- your content here -->
                <router-view></router-view>
            </transition>
        </div>
        <div class="container-fluid" v-else>
            <div class="row">
                <div
                    class="col-12 d-flex flex-row justify-content-center align-items-center"
                    style="height: 300px;"
                >
                    <img src="/img/spinner.gif" height="100" alt />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { db } from "../../main";
import { api } from "../../config";
import firebase from "firebase";
import moment from "moment";
import Noty from "noty";
export default {
    computed: {
        ...mapGetters({
            user: "user",
            loggedIn: "loggedIn",
            ready: "ready"
        })
    },
    mounted() {
        let self = this;

        if (this.loggedIn) {
            setInterval(() => {
                self.authenticateUser();
            }, 55000);
        }

        db.collection("channels")
            .where("active", "==", true)
            .onSnapshot(async doc => {
                doc.docChanges().forEach(async change => {
                    if (change.type === "modified") {
                        let request = await fetch(
                            `${api}/channels/messages/receipts/unread`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${self.$store.getters.token}`
                                }
                            }
                        );
                        let receipts = await request.json();

                        if (receipts.length) {
                            self.$eventBus.$emit("newMessage");
                        }
                    }
                });
            });

        // self.$eventBus.$on("newMessage", () => {
        //     new Noty({
        //         type: "info",
        //         theme: "sunset",
        //         layout: "bottomRight",
        //         text: `There are unread messages`,
        //         timeout: 5000
        //     }).show();
        // });

        db.collection("takeovers")
            .doc(self.user.uid)
            .onSnapshot(doc => {
                let takeover = doc.data();
                if (takeover.active) {
                    new Noty({
                        type: "info",
                        theme: "sunset",
                        layout: "bottomRight",
                        text: `${takeover.name} has taken over the conversation.`,
                        timeout: 5000
                    }).show();

                    self.$eventBus.$emit("disengageConversation");
                }
            });
    },
    methods: {
        authenticateUser() {
            let self = this;

            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(idToken => {
                    self.$store.dispatch("setToken", idToken);
                })
                .catch(error => {
                    self.$store.dispatch("setUser", null);
                    self.$store.dispatch("setToken", null);
                    self.$store.dispatch("logout");
                    console.log(error);
                });
        }
    }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.1s;
}

.fade-enter,
  .fade-leave-to
  /* .fade-leave-active in <2.1.8 */

 {
    opacity: 0;
}
</style>
