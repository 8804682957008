<template>
    <div class="container">
        <div class="row">
            <div
                class="col-12 d-flex flex-row justify-content-center align-items-center"
                style="height: 600px;"
            >
                <div id="firebaseui-auth-container"></div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import "../../node_modules/firebaseui/dist/firebaseui.css";
export default {
    mounted() {
        let self = this;

        let uiConfig = {
            signInFlow: 'popup',
            signInOptions: [
                {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    customParameters: {
                        // Forces account selection even when one account
                        // is available.
                        prompt: "select_account"
                    }
                }
            ],
            callbacks: {
                signInSuccessWithAuthResult() {
                    firebase.auth().onAuthStateChanged(async user => {
                        if (user) {
                            user.invite = null;
                            if (
                                self.$route.query.hasOwnProperty("invite") &&
                                self.$route.query.invite.length
                            ) {
                                user.invite = self.$route.query.invite;
                            }
                            await self.$store.dispatch(
                                "setToken",
                                await user.getIdToken()
                            );
                            await self.$store.dispatch("fetchUser", user);
                            self.$router.push("/");
                        }
                    });
                }
            }
        };
        let ui =
            firebaseui.auth.AuthUI.getInstance() ||
            new firebaseui.auth.AuthUI(firebase.auth());
        ui.start("#firebaseui-auth-container", uiConfig);
        ui.disableAutoSignIn();
    }
};
</script>
