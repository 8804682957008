var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{staticClass:"pt-1"},[_c('b-row',{staticClass:"border-bottom"},[_c('b-col',[_c('b-nav',{staticClass:"pb-3",attrs:{"pills":"","align":"right"}},[_c('b-nav-form',{staticClass:"mr-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('button',{staticClass:"mt-1 mr-1 btn btn-primary",class:{
							'active':
								_vm.filters.agent === null
						},on:{"click":function($event){_vm.filters.agent = null}}},[_vm._v("All Tickets")])]),_c('b-nav-form',{staticClass:"mr-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('button',{staticClass:"mt-1 mr-1 btn btn-primary",class:{
							'active':
								_vm.filters.agent === _vm.user.uid
						},on:{"click":function($event){_vm.filters.agent = _vm.user.uid}}},[_vm._v("My Tickets")])]),_c('b-nav-form',{staticClass:"mr-1"},[_c('b-form-select',{attrs:{"placeholder":"Filter by status","options":_vm.status},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),(_vm.user.master)?_c('b-nav-form',{staticClass:"mr-1"},[_c('b-form-select',{attrs:{"placeholder":"Filter by group","options":_vm.groups},model:{value:(_vm.filters.group),callback:function ($$v) {_vm.$set(_vm.filters, "group", $$v)},expression:"filters.group"}})],1):_vm._e(),_c('b-nav-form',{staticClass:"mr-1"},[_c('b-form-select',{attrs:{"placeholder":"Filter by category","options":_vm.categories},model:{value:(_vm.filters.category),callback:function ($$v) {_vm.$set(_vm.filters, "category", $$v)},expression:"filters.category"}})],1),_c('b-nav-form',{staticClass:"mr-1"},[_c('b-form-select',{attrs:{"placeholder":"Filter by priority","options":_vm.priorities},model:{value:(_vm.filters.priority),callback:function ($$v) {_vm.$set(_vm.filters, "priority", $$v)},expression:"filters.priority"}})],1),_c('b-nav-form',[_c('b-form-input',{staticClass:"mr-1",attrs:{"aria-label":"Input","placeholder":"Search here"},model:{value:(_vm.filters.term),callback:function ($$v) {_vm.$set(_vm.filters, "term", $$v)},expression:"filters.term"}})],1)],1)],1)],1),_c('b-row',[(_vm.ready)?_c('List',{attrs:{"tickets":_vm.hits}}):_c('div',{staticClass:"col-12 d-flex flex-row justify-content-center align-items-center",staticStyle:{"height":"300px"}},[_c('img',{attrs:{"src":"/img/spinner.gif","height":"100","alt":""}})])],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }