<template>
	<div>
		<Categories />
		<Groups />
		<Priorities />
	</div>
</template>

<script>
import Categories from "./Tickets/Categories";
import Groups from "./Tickets/Groups";
import Priorities from "./Tickets/Priorities";
export default {
	components: {
		Categories,
		Groups,
		Priorities
	}
};
</script>
