<template>
    <div>
        <b-row>
            <b-col cols="6">
                <b-card title="New Ticket">
                    <b-form @submit="submit" v-on:submit.prevent novalidate>
                        <div class="media">
                            <div class="media-body">
                                <h5 class="mt-0">
                                    {{ message.name }}
                                    <b-badge variant="secondary">
                                        {{
                                        message.websiteName
                                        }}
                                    </b-badge>
                                </h5>
                                <p>
                                    <code>{{ message.rawBody }}</code>
                                </p>
                            </div>
                        </div>

                        <div
                            class="form-group"
                            :class="{
								'form-group--error': $v.ticket.subject.$error
							}"
                        >
                            <label class="form__label">Subject:</label>
                            <input
                                type="text"
                                class="form-control form__input"
                                v-model.trim="$v.ticket.subject.$model"
                            />
                        </div>

                        <div
                            class="error mb-2 text-danger"
                            v-if="!$v.ticket.subject.required"
                        >Field is required</div>

                        <div
                            class="form-group"
                            :class="{
								'form-group--error': $v.ticket.category.$error
							}"
                        >
                            <label class="form__label">Category:</label>
                            <b-form-select
                                class="form__input"
                                v-model.trim="$v.ticket.category.$model"
                                :options="categories"
                            ></b-form-select>
                        </div>

                        <div
                            class="error mb-2 text-danger"
                            v-if="!$v.ticket.category.required"
                        >Field is required</div>

                        <div
                            class="form-group"
                            :class="{
								'form-group--error': $v.ticket.group.$error
							}"
                        >
                            <label class="form__label">Agent Group:</label>
                            <b-form-select
                                class="form__input"
                                v-model.trim="$v.ticket.group.$model"
                                :options="groups"
                            ></b-form-select>
                        </div>

                        <div
                            class="error mb-2 text-danger"
                            v-if="!$v.ticket.group.required"
                        >Field is required</div>

                        <div
                            class="form-group"
                            :class="{
								'form-group--error': $v.ticket.priority.$error
							}"
                        >
                            <label class="form__label">Priority:</label>
                            <b-form-select
                                class="form__input"
                                v-model.trim="$v.ticket.priority.$model"
                                :options="priorities"
                            ></b-form-select>
                        </div>

                        <div
                            class="error mb-2 text-danger"
                            v-if="!$v.ticket.priority.required"
                        >Field is required</div>

                        <div class="form-group">
                            <label>Note:</label>
                            <vue-editor
                                v-model="ticket.note"
                                :editorToolbar="customToolbar"
                                @text-change="setTicketNote"
                            ></vue-editor>
                        </div>

                        <div class="form-group">
                            <button
                                type="submit"
                                class="btn btn-primary btn-block rounded-0 text-uppercase"
                            >Create Ticket</button>
                        </div>

                        <div class="form-group">
                            <p
                                class="typo__p text-success"
                                v-if="submitStatus === 'OK'"
                            >Form submitted successfully.</p>
                            <p
                                class="typo__p text-danger"
                                v-if="submitStatus === 'ERROR'"
                            >Please fill the form correctly.</p>
                            <p
                                class="typo__p text-info"
                                v-if="submitStatus === 'PENDING'"
                            >Submitting . . .</p>
                        </div>
                    </b-form>
                </b-card>
            </b-col>
            <b-col cols="6">
                <b-card>
                    <Conversation
                        :channelId="message.channel"
                        :position="$route.params.message"
                        :typingArea="false"
                        :contentHeight="`580px`"
                        v-if="ready"
                    />
                    <div
                        v-else
                        class="col-12 d-flex flex-row justify-content-center align-items-center"
                        style="height: 600px;"
                    >
                        <img src="/img/spinner.gif" height="100" alt />
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { api } from "../../config";
import Conversation from "../../components/Conversation";
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";
import Noty from "noty";
import { required } from "vuelidate/lib/validators";
export default {
    data: () => ({
        message: {},
        ticket: {
            subject: null,
            category: null,
            group: null,
            priority: null,
            note: "",
            rawNote: ""
        },
        submitStatus: null,
        customToolbar: [
            ["bold", "italic", "underline"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["image"]
        ],
        categories: [],
        groups: [],
        priorities: [],
        ready: false
    }),
    computed: {
        ...mapGetters({
            user: "user"
        })
    },
    async mounted() {
        await this.getMessage(this.$route.params.message);
        await this.getTicketCategories();
        await this.getTicketGroups();
        await this.getTicketPriorities();
    },
    methods: {
        async getMessage(messageId) {
            let request = await fetch(
                `${api}/channels/messages/single/${messageId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`
                    }
                }
            );
            let response = await request.json();
            this.message = response.message;
            this.ready = true;
            this.ticket.message = messageId;
            this.ticket.user = this.message.user;
            this.ticket.agent = this.user.uid;
        },
        async getTicketCategories() {
            let request = await fetch(`${api}/tickets/categories`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.categories = [];
            response.forEach(category => {
                this.categories.push({
                    value: category.uid,
                    text: category.name
                });
            });
        },
        async getTicketGroups() {
            let request = await fetch(`${api}/users/groups`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.groups = [];
            response.forEach(group => {
                this.groups.push({
                    value: group.uid,
                    text: group.name
                });
            });
        },
        async getTicketPriorities() {
            let request = await fetch(`${api}/tickets/priorities`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.priorities = [];
            response.forEach(priority => {
                this.priorities.push({
                    value: priority.uid,
                    text: priority.name
                });
            });
        },
        setTicketNote() {
            this.ticket.rawNote = this.$options.filters.extractContent(
                this.ticket.note,
                true
            );
        },
        async submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitStatus = "ERROR";
            } else {
                this.submitStatus = "PENDING";
                let request = await fetch(`${api}/tickets`, {
                    method: "POST",
                    body: JSON.stringify(this.ticket),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`
                    }
                });
                let response = await request.json();

                new Noty({
                    type: "success",
                    theme: "sunset",
                    layout: "bottomRight",
                    text: `Ticket ID #${response.ticketId} created`,
                    timeout: 5000
                }).show();

                this.$router.push(`/tickets/view/${response.ticketId}`);
                this.submitStatus = "OK";
            }
        }
    },
    components: {
        Conversation,
        VueEditor
    },
    validations: {
        ticket: {
            subject: {
                required
            },
            category: {
                required
            },
            group: {
                required
            },
            priority: {
                required
            }
        }
    }
};
</script>
