import Vue from "vue";
import Vuex from "vuex";
import { api } from "../config";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: {},
		loggedIn: false,
		token: null,
		ready: false
	},
	getters: {
		user(state) {
			return state.user;
		},
		loggedIn(state) {
			return state.loggedIn;
		},
		token(state) {
			return state.token;
		},
		ready(state) {
			return state.ready;
		}
	},
	mutations: {
		SET_LOGGED_IN(state, value) {
			state.loggedIn = value;
		},
		SET_USER(state, data) {
			state.user = data;
		},
		SET_TOKEN(state, data) {
			state.token = data;
		},
		SET_READY(state, data) {
			state.ready = data;
		}
	},
	actions: {
		async fetchUser({ commit, rootState }, user) {
			if (user) {
				let request = await fetch(`${api}/users`, {
					method: "POST",
					body: JSON.stringify({
						uid: user.uid,
						displayName: user.displayName,
						email: user.email,
						contactNumber: "",
						photoURL: user.photoURL,
						invite: user.invite ? user.invite : null
					}),
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${rootState.token}`
					}
				});
				let response = await request.json();

				commit("SET_LOGGED_IN", true);
				commit("SET_USER", response);
			} else {
				commit("SET_LOGGED_IN", false);
				commit("SET_READY", null);
			}
			commit("SET_READY", true);
		},
		login({ commit }) {
			commit("SET_LOGGED_IN", true);
			commit("SET_READY", true);
		},
		async logout({ commit }) {
			commit("SET_LOGGED_IN", false);
		},
		async setToken({ commit }, payload) {
			commit("SET_TOKEN", payload);
		}
	},
	modules: {}
});
