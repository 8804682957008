<template>
    <div class="wrapper">
        <side-bar>
            <template slot="links">
                <sidebar-link to="/chats" name="Chats" icon="las la-comment" />
                <sidebar-link to="/tickets" name="Tickets" icon="las la-ticket-alt" />
                <sidebar-link to="/customers" name="Customers" icon="las la-address-book" />
                <sidebar-link
                    v-if="user.master === 1"
                    to="/agents"
                    name="Agents"
                    icon="las la-user-friends"
                />
                <sidebar-link
                    v-if="user.master === 1"
                    to="/websites"
                    name="Websites"
                    icon="las la-cube"
                />
                <sidebar-link
                    v-if="user.master === 1"
                    to="/settings"
                    name="Settings"
                    icon="las la-tools"
                />
                <!-- <sidebar-link to="/notifications" name="Notifications" icon="ti-bell" /> -->
            </template>
            <mobile-menu>
                <li class="nav-item">
                    <router-link class="nav-link" to="/logout">
                        <i class="ti-settings"></i>
                        <p>Logout</p>
                    </router-link>
                </li>
                <li class="divider"></li>
            </mobile-menu>
        </side-bar>
        <div class="main-panel">
            <top-navbar></top-navbar>

            <dashboard-content @click.native="toggleSidebar"></dashboard-content>
        </div>
    </div>
</template>
<style>
.form-control {
    border: 1px solid #e3e3e3 !important;
}
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import { mapGetters } from "vuex";
export default {
    components: {
        TopNavbar,
        ContentFooter,
        DashboardContent,
        MobileMenu
    },
    computed: {
        ...mapGetters({
            user: "user"
        })
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        }
    }
};
</script>
