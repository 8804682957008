import Vue from "vue";
import App from "./App";
import router from "./router/index";
import store from "./store";
import firebase from "firebase";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import moment from "moment";
import Vuelidate from "vuelidate";

import PaperDashboard from "./plugins/paperDashboard";
import "vue-notifyjs/themes/default.css";
import "./assets/css/conversation.css";

import { firebaseConfig } from "./config";
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const db = firebase.firestore();
db.enablePersistence();

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(PaperDashboard);
Vue.use(Vuelidate);

Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false;

Vue.filter("readableDateTime", value => {
    return moment(value).format("MMMM DD, YYYY hh:mm A");
});

Vue.filter("timeAgo", value => {
    return moment(value).fromNow();
});

Vue.filter("extractContent", value => {
    var temp = document.createElement("div");
    temp.innerHTML = value.replace(new RegExp("><", "g"), "> <");
    return temp.textContent || temp.innerText || " ";
});

Vue.filter("statusName", value => {
    let name = "";
    switch (value) {
        case 1:
            name = "Open";
            break;
        case 2:
            name = "Pending";
            break;
        case 3:
            name = "Spam";
            break;
        case 4:
            name = "Solved";
            break;
        default:
            name = "Invalid";
    }
    return name;
});

Vue.filter("unitNamePlurality", (value, unit) => {
    return `${unit}${value === 1 ? "" : "s"}`;
});

router.beforeEach((to, from, next) => {
    console.log(to);
    window.scrollTo(0, 0);
    if (!to.matched.some(record => record.meta.protected)) {
        //route is public, don't check for authentication
        if (to.matched.name === "Logout") {
            router.push("/logout");
        } else {
            next();
        }
    } else {
        //route is protected, if authenticated, proceed. Else, login
        firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                await store.dispatch("setToken", await user.getIdToken());

                if (store.getters.user.length) {
                    await store.dispatch("login");
                } else {
                    await store.dispatch("fetchUser", user);
                }
                next();
            } else {
                router.push({
                    path: "/login"
                });
            }
        });
    }
});

/* eslint-disable no-new */
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
