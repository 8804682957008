<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-4 offset-md-8">
                <b-nav-form class="float-right">
                    <b-form-input
                        aria-label="Input"
                        class="mr-1"
                        placeholder="Search here"
                        v-model="filters.term"
                    ></b-form-input>
                </b-nav-form>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <card>
                    <div slot="raw-content" class="table-responsive">
                        <b-table striped hover :items="customers"></b-table>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import { api } from "../config";
import moment from "moment";
import search from "@/libraries/Search";

export default {
    data: () => ({
        filters: search.setDefaultFilter({
            hitsPerPage: 20,
            page: 1,
            replica: "users",
            term: ""
        }),
        hits: [],
        customers: [],
        pages: [],
        pagination: {
            totalRows: null,
            items: []
        }
    }),
    async mounted() {
        await this.getCustomers();
    },
    computed: {
        searchFilters() {
            let filters = { ...this.filters };
            return search.getURLString(filters);
        }
    },
    methods: {
        async getCustomers() {
            let request = await fetch(
                `${api}/users/customers?${this.searchFilters}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`
                    }
                }
            );
            let data = await request.json();

            this.hits = data.hits;
            this.hits.map(hit => {
                dateCreated: moment(hit.createdAt).format("MM/DD/YYYY");
            });

            this.customers = [];
            this.hits.forEach(customer => {
                this.customers.push({
                    name: customer.name,
                    email: customer.email,
                    contactNumber: customer.contactNumber,
                    dateCreated: customer.createdAt
                });
            });

            let _tempPageItems = [];
            this.pagination.totalRows = data.nbHits;
            for (let index = 0; index < data.nbPages; index++) {
                _tempPageItems.push(index);
            }
            this.pagination.items = _tempPageItems;
        }
    },
    watch: {
        filters: {
            handler: function(query) {
                search.setURLParameters(query);
                this.getCustomers();
            },
            deep: true
        }
    }
};
</script>
<style></style>
