<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul></ul>
      </nav>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
