<template>
  <div class="media">
		<div class="media-body ml-0">
			<div class="d-flex align-items-center justify-content-between mb-1">
				<h6 class="mb-0">{{ channel.name }}</h6>
				<small class="small font-weight-bold">
					{{
					channel.websiteName
					}}
				</small>
			</div>
			<p class="font-italic mb-0 text-small">
				<v-clamp autoresize :max-lines="1">
					{{
					channel.rawBody
					}}
				</v-clamp>
			</p>
			<p class="font-italic mb-0 text-small">
				<b-badge>{{channel.presence}}</b-badge>
			</p>
		</div>
		<span
			v-show="channel.unreadCount >= 1"
			class="badge badge-danger unread-badge text-center"
		>{{ channel.unreadCount | unreadCountLimiter }}</span>
	</div>
</template>

<script>
import VClamp from "vue-clamp";
export default {
	props: ["channel"],
	components: {
        VClamp,
	},
    filters: {
        unreadCountLimiter(value) {
            return parseInt(value) > 9 ? "9+" : value;
        }
    }
}
</script>