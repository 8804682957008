<template>
	<div class="row">
		<div class="col-xl-8 col-lg-7 col-md-6">
			<b-card>
				<b-table striped hover :items="websites"></b-table>
			</b-card>
		</div>
		<div class="col-xl-4 col-lg-5 col-md-6">
			<b-card title="Add Website">
				<b-form
					@submit="submit"
					v-on:submit.prevent
					novalidate
					class="mt-1"
				>
					<small class="mb-2">
						To see Transponder on your website, you'll need to add a
						bit of code or configure an integration...
					</small>
					<div
						class="form-group"
						:class="{
							'form-group--error': $v.name.$error
						}"
					>
						<label for="websiteName" class="form__label"
							>Name</label
						>
						<input
							type="text"
							class="form-control form__input"
							id="websiteName"
							name="websiteName"
							v-model.trim="$v.name.$model"
						/>
					</div>

					<div
						class="error mb-2 text-danger"
						v-if="!$v.name.required"
					>
						Field is required
					</div>
					<div
						class="error mb-2 text-danger"
						v-if="!$v.name.minLength"
					>
						Name must have at least
						{{ $v.name.$params.minLength.min }}
						letters.
					</div>

					<div
						class="form-group"
						:class="{
							'form-group--error': $v.domain.$error
						}"
					>
						<label for="websiteDomain" class="form__label"
							>Domain</label
						>
						<input
							type="text"
							class="form-control form__input"
							id="websiteDomain"
							name="websiteDomain"
							v-model.trim="$v.domain.$model"
						/>
					</div>

					<div
						class="error mb-2 text-danger"
						v-if="!$v.domain.required"
					>
						Field is required
					</div>

					<div class="form-group">
						<button
							type="submit"
							class="btn btn-primary btn-block rounded-0 text-uppercase"
						>
							Submit
						</button>
					</div>

					<div class="form-group">
						<p
							class="typo__p text-success"
							v-if="submitStatus === 'OK'"
						>
							Form submitted successfully.
						</p>
						<p
							class="typo__p text-danger"
							v-if="submitStatus === 'ERROR'"
						>
							Please fill the form correctly.
						</p>
						<p
							class="typo__p text-info"
							v-if="submitStatus === 'PENDING'"
						>
							Submitting . . .
						</p>
					</div>
				</b-form>
			</b-card>
		</div>
	</div>
</template>

<script>
import { api } from "../config";
import Noty from "noty";
import { required, minLength } from "vuelidate/lib/validators";
export default {
	data: () => ({
		name: "",
		domain: "",
		submitStatus: null,
		websites: []
	}),
	async mounted() {
		await this.getWebsites();
	},
	methods: {
		async getWebsites() {
			let request = await fetch(`${api}/organizations/websites`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`
				}
			});
			let response = await request.json();
			this.websites = [];
			response.forEach(website => {
				this.websites.push({
					name: website.name,
					domain: website.domain,
					status: website.status === 1 ? "Active" : "Inactive"
				});
			});
		},
		async submit() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "PENDING";
				let request = await fetch(`${api}/organizations/websites`, {
					method: "POST",
					body: JSON.stringify({
						name: this.name,
						domain: this.domain
					}),
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${this.$store.getters.token}`
					}
				});
				let response = await request.json();
				await this.getWebsites();
				console.log(response.url);

				new Noty({
					type: "success",
					theme: "sunset",
					layout: "bottomRight",
					text: `Added ${this.domain} to your websites`,
					timeout: 5000
				}).show();

				this.name = "";
				this.domain = "";
				this.submitStatus = "OK";
			}
		}
	},
	validations: {
		name: {
			required,
			minLength: minLength(3)
		},
		domain: {
			required
		}
	}
};
</script>
