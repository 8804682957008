<template>
	<nav class="navbar navbar-expand-lg navbar-light">
		<div class="container-fluid">
			<a class="navbar-brand" href="#">{{ routeName }}</a>
			<button
				class="navbar-toggler navbar-burger"
				type="button"
				@click="toggleSidebar"
				:aria-expanded="$sidebar.showSidebar"
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-bar"></span>
				<span class="navbar-toggler-bar"></span>
				<span class="navbar-toggler-bar"></span>
			</button>
			<div class="collapse navbar-collapse">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item">
						<router-link class="nav-link" to="/logout">
							<i class="las la-power-off"></i>
							<p>Logout</p>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>
<script>
export default {
	computed: {
		routeName() {
			const { name } = this.$route;
			return this.capitalizeFirstLetter(name);
		}
	},
	data() {
		return {
			activeNotifications: false
		};
	},
	methods: {
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		toggleNotificationDropDown() {
			this.activeNotifications = !this.activeNotifications;
		},
		closeDropDown() {
			this.activeNotifications = false;
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		}
	}
};
</script>
<style></style>
