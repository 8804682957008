export const firebaseConfig = {
	apiKey: "AIzaSyBLiL40UoumAA1IS9CYgA2Yv9IePAX2PIU",
	authDomain: "scgc-transponder.firebaseapp.com",
	databaseURL: "https://scgc-transponder.firebaseio.com",
	projectId: "scgc-transponder",
	storageBucket: "scgc-transponder.appspot.com",
	messagingSenderId: "730167353407",
	appId: "1:730167353407:web:289ec03881a5d66cb0588a",
	measurementId: "G-QB9Q4BFVXE"
};

// export const firebaseConfig = {
// 	apiKey: "AIzaSyBy7ONuNNlREq1y0VsNkZ-L_HKKgOwyPfs",
// 	authDomain: "transponder-dev.firebaseapp.com",
// 	databaseURL: "https://transponder-dev-default-rtdb.firebaseio.com",
// 	projectId: "transponder-dev",
// 	storageBucket: "transponder-dev.appspot.com",
// 	messagingSenderId: "52655632589",
// 	appId: "1:52655632589:web:05acc07e4c1ee8dcb13984",
// 	measurementId: "G-GLM1MRGKYT"
// };

// export const api = "https://transponder-dev.uc.r.appspot.com";

export const api = "https://mayday-api.transcycle.com.ph";
