<template>
    <div>
        <div
            class="px-4 py-5 bg-white overflow-auto"
            :style="`height:${contentHeight};`"
            ref="chatBox"
            v-show="ready"
        >
            <!-- Sender Message-->
            <div
                class="media w-50"
                v-for="(message, index) in messages"
                :key="index"
                v-bind:class="{
                    '': message.user !== user.uid && message.userType !== 'bot',
                    'ml-auto':
                        message.user === user.uid ||
                        message.userType === 'bot' ||
                        message.userType === 'agent',
                }"
                :id="message.uid"
                :data-user="`UID: ${message.uid} | Type: ${message.userType}`"
            >
                <div
                    class="media-body"
                    v-bind:class="{
                        'ml-3':
                            message.user !== user.uid &&
                            message.userType !== 'bot',
                        '':
                            message.user === user.uid ||
                            message.userType === 'bot' ||
                            message.userType === 'agent',
                    }"
                >
                    <div
                        class="rounded py-2 px-3 mb-2"
                        v-bind:class="{
                            light:
                                message.uid !== position &&
                                message.user !== user.uid &&
                                message.userType !== 'bot' &&
                                message.userType !== 'agent',
                            'bg-primary':
                                message.uid !== position &&
                                (message.user === user.uid ||
                                    message.userType === 'bot' ||
                                    message.userType === 'agent'),
                            'bg-secondary': message.uid === position,
                        }"
                    >
                        <a
                            href="javascript:void(0);"
                            @click="showMessageActions(message)"
                        >
                            <p
                                class="text-small mb-0 text-left message-body"
                                v-bind:class="{
                                    'text-muted':
                                        message.uid !== position &&
                                        message.user !== user.uid &&
                                        message.userType !== 'bot' &&
                                        message.userType !== 'agent',
                                    'text-white':
                                        message.uid === position ||
                                        message.user === user.uid ||
                                        message.userType === 'bot' ||
                                        message.userType === 'agent',
                                }"
                            >
                                {{ message.rawBody }}
                            </p>
                        </a>
                    </div>
                    <p
                        class="small text-muted timestamp timeago"
                        v-b-tooltip.hover
                        :title="`${moment(message.createdAt).format(
                            'MMMM DD, YYYY hh:mm A'
                        )}${
                            message.hasOwnProperty('name') &&
                            message.userType !== 'customer'
                                ? ' - ' + message.name
                                : ''
                        }`"
                        placement="bottom"
                    >
                        <vue-moments-ago
                            prefix
                            suffix="ago"
                            :date="message.createdAt"
                        ></vue-moments-ago>
                    </p>
                </div>
            </div>
        </div>

        <div class="row" v-show="!ready">
            <div
                class="col-12 d-flex flex-row justify-content-center align-items-center"
                style="height: 600px"
            >
                <img src="/img/spinner.gif" height="100" alt />
            </div>
        </div>

        <!-- Typing area -->
        <form
            @submit="send"
            v-on:submit.prevent
            class="light mt-2"
            v-if="typingArea && presence.user === user.uid"
        >
            <div class="input-group light-border">
                <b-form-textarea
                    placeholder="Type a message"
                    aria-describedby="button-addon2"
                    class="form-control rounded-0 border-0 py-4"
                    v-model="body"
                    rows="3"
                    max-rows="6"
                />
                <div class="input-group-append">
                    <button
                        id="button-addon2"
                        size="lg"
                        type="submit"
                        class="btn btn-link"
                    >
                        <i
                            class="las la-paper-plane"
                            style="font-size: 30px"
                        ></i>
                    </button>
                </div>
            </div>
        </form>

        <b-modal
            v-model="messageActionsModal"
            centered
            hide-footer
            title="Message Actions"
        >
            <div class="d-block text-center">
                <b-button variant="success" @click="createTicket"
                    >Create Ticket</b-button
                >
            </div>
        </b-modal>

        <b-modal
            ref="chatPresenceModal"
            centered
            title="Chat Agent"
            ok-title="Yes"
            cancel-title="No"
            @ok="arrive(channelId, true)"
            @cancel="$refs.chatPresenceModal.hide()"
        >
            <div class="d-block text-center">
                <b>{{ this.presence.name }}</b> is
                <span v-if="currentAgentTyping">currently active in </span
                ><spanv v-else>viewing</spanv> this conversation. <br />Do you
                want to takeover?
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { api } from "../config";
import { db } from "../main";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import VueMomentsAgo from "vue-moments-ago";
import Noty from "noty";
export default {
    props: ["channelId", "position", "typingArea", "contentHeight"],
    data: () => ({
        messages: [],
        body: "",
        messageActionsModal: false,
        message: {},
        ready: false,
        checkedParticipant: false,
        presence: { name: "" },
        unsubscribe: null,
        typing: false,
        currentAgentTyping: false,
    }),
    computed: {
        ...mapGetters({
            user: "user",
        }),
    },
    async mounted() {
        await this.getMessages();
        await this.updateAllMessageReceipts(this.channelId);
        await this.checkPresence(this.channelId);
    },
    async beforeDestroy() {
        if (this.presence.user === this.user.uid) {
            await this.leave(this.channelId);
        }

        this.unsubscribe();
    },
    methods: {
        async getMessages() {
            let self = this;
            this.messages = [];

            let request = await fetch(
                `${api}/channels/messages/${self.channelId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${self.$store.getters.token}`,
                    },
                }
            );
            let response = await request.json();
            self.channel = response.channel;
            self.messages = response.messages

            if (self.position) {
                setTimeout(() => {
                    document.getElementById(self.position).scrollIntoView();
                }, 500);
            } else {
                self.scrollToBottom();
            }

            this.ready = true;

            this.unsubscribe = db
                .collection("channels")
                .doc(self.channelId)
                .onSnapshot(async (doc) => {
                    let message = doc.data();
                    let index = self.messages.findIndex(
                        (m) => m.uid === message.uid
                    );
                    if (index < 0) {
                        self.messages.push(message);
                        self.scrollToBottom();

                        if (
                            message.user !== self.user.uid &&
                            message.channel === self.channel.uid
                        ) {
                            setTimeout(async () => {
                                await self.updateMessageReceipt(message.uid);
                            }, 1000);
                        }
                    }
                });

            //   setTimeout(() => {
            //     self.$refs.chatBox.style.height = `${self.$refs.chatBox.parentElement.offsetHeight}px`;
            //   }, 1000);

            if (!self.checkedParticipant) {
                self.addParticipant(self.channel.uid);
            }
        },
        async send() {
            let message = {
                uid: uuidv4(),
                user: this.user.uid,
                userType: "agent",
                rawBody: this.body,
                body: this.body,
                createdAt: moment().format(),
                active: true,
            };

            this.body = "";

            await db.collection("channels").doc(this.channel.uid).set(message);
        },
        scrollToBottom() {
            let self = this;
            let duration = self.ready ? 0 : 500;
            setTimeout(() => {
                self.$refs.chatBox.scrollTop =
                    self.$refs.chatBox.scrollHeight -
                    self.$refs.chatBox.clientHeight;
            }, duration);
        },
        showMessageActions(message) {
            this.message = message;
            this.messageActionsModal = true;
        },
        createTicket() {
            this.messageActionsModal = false;
            this.$router.push(`/tickets/new/${this.message.uid}`);
        },
        async updateAllMessageReceipts(channelId) {
            await fetch(`${api}/channels/messages/receipts/all`, {
                method: "POST",
                body: JSON.stringify({
                    channel: channelId,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`,
                },
            });
        },
        async updateMessageReceipt(messageId) {
            await fetch(`${api}/channels/messages/receipts`, {
                method: "POST",
                body: JSON.stringify({
                    message: messageId,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`,
                },
            });
        },
        async addParticipant(channelId) {
            await fetch(`${api}/channels/participants`, {
                method: "POST",
                body: JSON.stringify({
                    channel: channelId,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`,
                },
            });

            this.checkedParticipant = true;
        },
        async checkPresence(channel) {
            let self = this;

            let presence = await db.collection("presences").doc(channel).get();

            if (presence.exists) {
                db.collection("presences")
                    .doc(channel)
                    .onSnapshot(
                        (doc) => {
                            this.presence = doc.data();

                            if (
                                this.presence.type === "arrive" &&
                                this.presence.user !== this.user.uid
                            ) {
                                this.$refs.chatPresenceModal.show();
                            } else {
                                if (
                                    this.presence.user !== this.user.uid &&
                                    this.presence.type !== "arrive"
                                ) {
                                    this.arrive(channel, false);
                                }
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            } else {
                this.arrive(channel, false);
            }

            await db
                .collection("activities")
                .doc(channel)
                .onSnapshot((doc) => {
                    let activity = doc.data();
                    if(activity) this.currentAgentTyping = activity.agentTyping;
                });
        },
        async arrive(channel, takeover) {
            let presenceRequest = await db
                .collection("presences")
                .doc(channel)
                .get();
            let presence = presenceRequest.data();

            let result = await db.collection("presences").doc(channel).set({
                user: this.user.uid,
                name: this.user.name,
                group: this.user.groupName,
                type: "arrive",
                takeover,
                createdAt: moment().format(),
            });

            if (takeover) {
                await db.collection("takeovers").doc(presence.user).set({
                    user: this.user.uid,
                    name: this.user.name,
                    group: this.user.groupName,
                    active: true,
                    channel,
                    createdAt: moment().format(),
                });
                this.$eventBus.$emit("takeover");
            }

            this.$refs.chatPresenceModal.hide();
        },
        async leave(channel) {
            let result = await db.collection("presences").doc(channel).set({
                user: this.user.uid,
                name: this.user.name,
                group: this.user.groupName,
                takeover: false,
                type: "leave",
                createdAt: moment().format(),
            });
        },
    },
    watch: {
        async body(value) {
            await db.collection("activities").doc(this.channel.uid).set({
                agentTyping: true,
            });
            this.typing = true;

            if (this.typing) {
                setTimeout(async () => {
                    await db
                        .collection("activities")
                        .doc(this.channel.uid)
                        .set({
                            agentTyping: false,
                        });
                    this.typing = false;
                }, 5000); // 5 sec delay
            }
        },
    },
    components: {
        VueMomentsAgo,
    },
};
</script>

<style>
.light {
    background-color: #e9e9e9 !important;
}
.light-border {
    border: 1px solid #e9e9e9 !important;
}
.message-body {
    white-space: pre-line;
}
</style>