<template>
    <div class="row">
        <div class="col-xl-8 col-lg-7 col-md-6">
            <b-card>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Group</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="agent in agents" :key="agent.uid">
                            <td>{{agent.name}}</td>
                            <td>{{agent.email}}</td>
                            <td>
                                <a
                                    href="javascript:void(0);"
                                    v-if="user.master === 1"
                                    @click="showChangeGroupModal(agent)"
                                >{{agent.groupName}}</a>
                                <span v-else>{{agent.groupName}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-card>
        </div>
        <div class="col-xl-4 col-lg-5 col-md-6">
            <b-card title="Invite Agent">
                <b-form @submit="submit" v-on:submit.prevent novalidate class="mt-1">
                    <small class="mb-2">
                        Grant access to your team by adding them as agents.
                        Enter an email address to invite a new user...
                    </small>
                    <div
                        class="form-group"
                        :class="{
							'form-group--error': $v.inviteEmail.$error
						}"
                    >
                        <label for="inviteEmail" class="form__label">Email</label>
                        <input
                            type="text"
                            class="form-control form__input"
                            id="inviteEmail"
                            name="inviteEmail"
                            v-model.trim="$v.inviteEmail.$model"
                        />
                    </div>
                    <div
                        class="error mb-2 text-danger"
                        v-if="!$v.inviteEmail.required"
                    >Field is required</div>
                    <div
                        class="error mb-2 text-danger"
                        v-if="!$v.inviteEmail.email"
                    >Valid email address is required.</div>
                    <div class="form-group">
                        <button
                            type="submit"
                            class="btn btn-primary btn-block rounded-0 text-uppercase"
                        >Send Invite</button>
                    </div>

                    <div class="form-group">
                        <p
                            class="typo__p text-success"
                            v-if="submitStatus === 'OK'"
                        >Form submitted successfully.</p>
                        <p
                            class="typo__p text-danger"
                            v-if="submitStatus === 'ERROR'"
                        >Please fill the form correctly.</p>
                        <p
                            class="typo__p text-info"
                            v-if="submitStatus === 'PENDING'"
                        >Submitting . . .</p>
                    </div>
                </b-form>
            </b-card>

            <b-modal
                ref="updateGroupModal"
                centered
                hide-footer
                :title="`Group Assignment for ${selectedUser.name}`"
            >
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>New group</label>
                                <select v-model="selectedGroup" class="form-control">
                                    <option
                                        v-for="group in groups"
                                        :key="group.uid"
                                        :value="group.uid"
                                    >{{ group.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="text-center col-md-12">
                            <button class="btn btn-primary" @click="changeGroup">Update</button>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { api } from "../config";
import Noty from "noty";
import { required, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
    data: () => ({
        inviteEmail: "",
        agents: [],
        groups: [],
        selectedUser: {
            name: "'"
        },
        selectedGroup: null,
        submitStatus: null
    }),
    computed: {
        ...mapGetters({
            user: "user"
        })
    },
    async mounted() {
        await this.getAgents();
        await this.getGroups();
    },
    methods: {
        async getAgents() {
            let request = await fetch(`${api}/users/agents`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.agents = [];
            response.forEach(agent => {
                this.agents.push({
                    uid: agent.uid,
                    name: agent.name,
                    email: agent.email,
                    groupName: agent.groupName,
                    groupId: agent.groupId
                });
            });
        },
        async getGroups() {
            let request = await fetch(`${api}/users/groups`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.groups = [];
            response.forEach(group => {
                this.groups.push({
                    uid: group.uid,
                    name: group.name
                });
            });
        },
        async submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitStatus = "ERROR";
            } else {
                this.submitStatus = "PENDING";
                await fetch(`${api}/users/agents/invites`, {
                    method: "POST",
                    body: JSON.stringify({
                        email: this.inviteEmail
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`
                    }
                });

                new Noty({
                    type: "success",
                    theme: "sunset",
                    layout: "bottomRight",
                    text: `Email invitation sent to ${this.inviteEmail}`,
                    timeout: 5000
                }).show();

                this.inviteEmail = "";
                this.submitStatus = "OK";
            }
        },
        async showChangeGroupModal(user) {
            console.log(user);
            this.selectedUser = user;
            this.$refs.updateGroupModal.show();
        },
        async changeGroup() {
            this.$refs.updateGroupModal.hide();
            await fetch(`${api}/users/groups/assignments/update`, {
                method: "POST",
                body: JSON.stringify({
                    user: this.selectedUser.uid,
                    group: this.selectedGroup
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });

            await this.getAgents();
        }
    },
    validations: {
        inviteEmail: {
            required,
            email
        }
    }
};
</script>
