<template>
    <div class="row">
        <div class="col-xl-4">
            <b-card class="overflow-auto" style="height:500px">
                <div class="list-group rounded-0" v-if="loaded">
                    <a
                        href="javascript:void(0);"
                        class="list-group-item list-group-item-action mb-2 border-top rounded-0"
                        v-for="c in channels"
                        :key="c.uid"
                        @click="setChannel(c)"
                        v-bind:class="{
							'active text-white': c.uid === channel.uid
						}"
                    >
                        <Chat :channel="c" :key="c.uid"/>
                    </a>
                </div>
                <div class="row" v-else>
                    <div
                        class="col-12 d-flex flex-row justify-content-center align-items-center"
                        style="height: 600px"
                    >
                        <img src="/img/spinner.gif" height="100" alt />
                    </div>
                </div>
            </b-card>
            <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.rows"
                :per-page="pagination.perPage"
                size="sm"
                align="right"
                @change="loadNext"
            ></b-pagination>
        </div>
        <div class="col-xl-5">
            <b-card>
                <Conversation
                    v-if="ready"
                    :channelId="channel.uid"
                    :typingArea="true"
                    :contentHeight="`440px`"
                />
                <div
                    v-else
                    class="d-flex flex-row justify-content-center text-center align-items-center"
                    style="height: 300px;"
                >
                    <b-container>
                        <b-row class="mb-3">
                            <b-col>
                                <i style="font-size:30px;" class="las la-comment-slash"></i>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <h6>No conversation selected</h6>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
            </b-card>
        </div>
        <div class="col-3">
            <card class="card-user" v-if="customer && customer.websiteData">
                <div>
                    <div class="author mt-3">
                        <img class="avatar border-white" :src="customer.photo" alt="..." />
                        <h4 class="title">
                            {{customer.name}}
                            <br />
                            <small>{{customer.email}}</small>
                            <br />
                            <small>{{customer.contactNumber}}</small>
                        </h4>
                    </div>
                    <p class="description text-center">{{customer.description}}</p>
                </div>
                <hr />
                <div class="row">
                    <div class="col-12">
                        Customer Data:
                        <b-table
                            striped
                            hover
                            responsive
                            :items="customer.websiteData.orders"
                            show-empty
                        ></b-table>
                        <div class="pt-2 text-center">
                            <a
                                v-if="customer.websiteData.orders"
                                class="btn btn-info btn-sm"
                                :href="customer.websiteData.detailsURL"
                                target="_blank"
                            >View more</a>
                        </div>
                    </div>
                </div>
            </card>
            <b-card v-else-if="customer && !customer.websiteData">
                <div
                    class="d-flex flex-row justify-content-center text-center align-items-center"
                    style="height: 300px;"
                >
                    <b-container>
                        <b-row class="mb-3">
                            <b-col>
                                <i style="font-size:30px;" class="las la-user-slash"></i>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <h6>There is no customer details available.</h6>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
            </b-card>
            <b-card v-else>
                <div
                    class="d-flex flex-row justify-content-center text-center align-items-center"
                    style="height: 300px;"
                >
                    <b-container>
                        <b-row class="mb-3">
                            <b-col>
                                <i style="font-size:30px;" class="las la-user-slash"></i>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <h6>Select a conversation to view the customer details</h6>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import { api } from "../config";
import { db } from "../main";
import { mapGetters } from "vuex";
import Conversation from "../components/Conversation";
import Chat from "../components/Chat";
export default {
    data: () => ({
        channels: [],
        channel: {
            uid: null
        },
		customer: null,
        ready: false,
        loaded: false,
        pagination: {
            perPage: 50,
            currentPage: 1,
            rows: 0
        }
    }),
    computed: {
        ...mapGetters({
            user: "user"
        }),
        
        pageOffset() {
            if(this.pagination.currentPage == 1) return 0
            else return (this.pagination.perPage * this.pagination.currentPage) - this.pagination.perPage
        }
    },
    async mounted() {
        this.rowCount();
        await this.getChannels();
        
        this.$eventBus.$on("newMessage", () => {
            this.getChannels();
		});
		
		this.$eventBus.$on("takeover", () => {
           this.getChannels();
        });

        this.$eventBus.$on("disengageConversation", async () => {
            this.ready = false;

            await db
                .collection("takeovers")
                .doc(this.user.uid)
                .update({
                    active: false
                });
		});
    },
    methods: {
        async getChannels(limit, offset) {
            let request = await fetch(`${api}/channels?limit=${this.pagination.perPage}&offset=${this.pageOffset}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.channels = []
            this.channels = response.channels;
            this.loaded = true

            if (this.channels.filter(e => e.unreadCount !== 0).length) {
                let title = window.document.title;
                let icon = window.document.querySelector("link[rel='icon']");
                let iconURL = icon.getAttribute("href");
                setInterval(() => {
                    window.document.title =
                        "Unread messages - Mayday Live Chat";
                    icon.setAttribute(
                        "href",
                        iconURL.replace("favicon", "favicon-h")
                    );
                    setTimeout(() => {
                        window.document.title = title;
                        icon.setAttribute("href", iconURL);
                    }, 1500);
                }, 3000);
			}
		},
        async rowCount() {
            let request = await fetch(`${api}/channels/count`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.pagination.rows = response.count;
        },
        async setChannel(channel) {
            this.ready = false;
            this.channel = channel;
            await this.getCustomer(channel.userId);
            setTimeout(() => {
                this.ready = true;
            }, 1000);

            setTimeout(async () => {
                await this.getChannels();
            }, 5000);
        },
        async getCustomer(uid) {
            let request = await fetch(`${api}/users/customers/${uid}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });

            let response = await request.json();
            this.customer = response;
        },
        loadNext() {
            this.loaded = false
            setTimeout(() => {
                this.getChannels(this.pagination.perPage, this.pageOffset);
            }, 1000)
        }
	},
    components: {
		Conversation,
		Chat
    }
};
</script>