<template>
    <b-row class="mb-3">
        <b-col cols="8">
            <b-card title="Agent Groups">
                <b-table striped hover :items="groups" class="mt-3"></b-table>
            </b-card>
        </b-col>
        <b-col cols="4">
            <b-card title="Add  Agent Group">
                <b-form @submit="submit" v-on:submit.prevent>
                    <div
                        class="form-group"
                        :class="{
							'form-group--error': $v.name.$error
						}"
                    >
                        <label class="form__label">Name</label>
                        <input
                            type="text"
                            class="form-control form__input"
                            v-model.trim="$v.name.$model"
                        />
                    </div>

                    <div class="error mb-2 text-danger" v-if="!$v.name.required">Field is required</div>
                    <div class="error mb-2 text-danger" v-if="!$v.name.minLength">
                        Name must have at least
                        {{ $v.name.$params.minLength.min }}
                        letters.
                    </div>

                    <div class="form-group">
                        <button
                            type="submitGroup"
                            class="btn btn-primary btn-block rounded-0 text-uppercase"
                        >Submit</button>
                    </div>

                    <div class="form-group">
                        <p
                            class="typo__p text-success"
                            v-if="submitStatus === 'OK'"
                        >Form submitted successfully.</p>
                        <p
                            class="typo__p text-danger"
                            v-if="submitStatus === 'ERROR'"
                        >Please fill the form correctly.</p>
                        <p
                            class="typo__p text-info"
                            v-if="submitStatus === 'PENDING'"
                        >Submitting . . .</p>
                    </div>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { api } from "../../../config";
import { required, minLength } from "vuelidate/lib/validators";
export default {
    data: () => ({
        name: "",
        submitStatus: null,
        groups: []
    }),
    async mounted() {
        await this.getUserGroups();
    },
    methods: {
        async getUserGroups() {
            let request = await fetch(`${api}/users/groups`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.groups = [];
            response.forEach(group => {
                this.groups.push({
                    name: group.name
                });
            });
        },
        async submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitStatus = "ERROR";
            } else {
                this.submitStatus = "PENDING";
                await fetch(`${api}/users/groups`, {
                    method: "POST",
                    body: JSON.stringify({
                        name: this.name
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`
                    }
                });

                await this.getUserGroups();
                this.name = null;
                this.submitStatus = "OK";
            }
        }
    },
    validations: {
        name: {
            required,
            minLength: minLength(3)
        }
    }
};
</script>
