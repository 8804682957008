var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{attrs:{"title":"New Ticket"}},[_c('b-form',{attrs:{"novalidate":""},on:{"submit":[_vm.submit,function($event){$event.preventDefault();}]}},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body"},[_c('h5',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(_vm.message.name)+" "),_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(_vm.message.websiteName)+" ")])],1),_c('p',[_c('code',[_vm._v(_vm._s(_vm.message.rawBody))])])])]),_c('div',{staticClass:"form-group",class:{
								'form-group--error': _vm.$v.ticket.subject.$error
							}},[_c('label',{staticClass:"form__label"},[_vm._v("Subject:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.ticket.subject.$model),expression:"$v.ticket.subject.$model",modifiers:{"trim":true}}],staticClass:"form-control form__input",attrs:{"type":"text"},domProps:{"value":(_vm.$v.ticket.subject.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.ticket.subject, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),(!_vm.$v.ticket.subject.required)?_c('div',{staticClass:"error mb-2 text-danger"},[_vm._v("Field is required")]):_vm._e(),_c('div',{staticClass:"form-group",class:{
								'form-group--error': _vm.$v.ticket.category.$error
							}},[_c('label',{staticClass:"form__label"},[_vm._v("Category:")]),_c('b-form-select',{staticClass:"form__input",attrs:{"options":_vm.categories},model:{value:(_vm.$v.ticket.category.$model),callback:function ($$v) {_vm.$set(_vm.$v.ticket.category, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.ticket.category.$model"}})],1),(!_vm.$v.ticket.category.required)?_c('div',{staticClass:"error mb-2 text-danger"},[_vm._v("Field is required")]):_vm._e(),_c('div',{staticClass:"form-group",class:{
								'form-group--error': _vm.$v.ticket.group.$error
							}},[_c('label',{staticClass:"form__label"},[_vm._v("Agent Group:")]),_c('b-form-select',{staticClass:"form__input",attrs:{"options":_vm.groups},model:{value:(_vm.$v.ticket.group.$model),callback:function ($$v) {_vm.$set(_vm.$v.ticket.group, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.ticket.group.$model"}})],1),(!_vm.$v.ticket.group.required)?_c('div',{staticClass:"error mb-2 text-danger"},[_vm._v("Field is required")]):_vm._e(),_c('div',{staticClass:"form-group",class:{
								'form-group--error': _vm.$v.ticket.priority.$error
							}},[_c('label',{staticClass:"form__label"},[_vm._v("Priority:")]),_c('b-form-select',{staticClass:"form__input",attrs:{"options":_vm.priorities},model:{value:(_vm.$v.ticket.priority.$model),callback:function ($$v) {_vm.$set(_vm.$v.ticket.priority, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.ticket.priority.$model"}})],1),(!_vm.$v.ticket.priority.required)?_c('div',{staticClass:"error mb-2 text-danger"},[_vm._v("Field is required")]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Note:")]),_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},on:{"text-change":_vm.setTicketNote},model:{value:(_vm.ticket.note),callback:function ($$v) {_vm.$set(_vm.ticket, "note", $$v)},expression:"ticket.note"}})],1),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary btn-block rounded-0 text-uppercase",attrs:{"type":"submit"}},[_vm._v("Create Ticket")])]),_c('div',{staticClass:"form-group"},[(_vm.submitStatus === 'OK')?_c('p',{staticClass:"typo__p text-success"},[_vm._v("Form submitted successfully.")]):_vm._e(),(_vm.submitStatus === 'ERROR')?_c('p',{staticClass:"typo__p text-danger"},[_vm._v("Please fill the form correctly.")]):_vm._e(),(_vm.submitStatus === 'PENDING')?_c('p',{staticClass:"typo__p text-info"},[_vm._v("Submitting . . .")]):_vm._e()])])],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',[(_vm.ready)?_c('Conversation',{attrs:{"channelId":_vm.message.channel,"position":_vm.$route.params.message,"typingArea":false,"contentHeight":`580px`}}):_c('div',{staticClass:"col-12 d-flex flex-row justify-content-center align-items-center",staticStyle:{"height":"600px"}},[_c('img',{attrs:{"src":"/img/spinner.gif","height":"100","alt":""}})])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }