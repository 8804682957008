import { stringify } from 'query-string'
import router from '@/router'
import { isEmpty } from 'lodash'

export default {
    setDefaultFilter(defaultFilter) {
        let filters = this.hasQueryParams() ? this.getQueryParams() : defaultFilter
        return { ...filters }
    },
    getFiltersQueryString(dataFilters) {
        let filters = this.hasQueryParams() ? this.getQueryParams() : dataFilters
        return this.getURLString(filters)
    },
    getURLString(filterObj) {
        return stringify(filterObj, { arrayFormat: 'bracket' })
    },
    getQueryParams() {
        return router.app.$route.query
    },
    hasQueryParams() {
        return !isEmpty(this.getQueryParams())
    },
    setURLParameters(filters) {
        let queryString = this.getURLString(filters)
        history.pushState(filters, 'Search', `${router.app.$route.path}?${queryString}`)
    },
}