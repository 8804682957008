<template>
	<b-row>
		<b-col cols="6">
			<b-card title="Set Organization">
				<b-form @submit="submit" v-on:submit.prevent>
					<div
						class="form-group"
						:class="{
							'form-group--error': $v.organization.name.$error
						}"
					>
						<label for="organizationName" class="form__label"
							>Name</label
						>
						<input
							type="text"
							class="form-control form__input"
							id="organizationName"
							name="organizationName"
							v-model.trim="$v.organization.name.$model"
						/>
					</div>

					<div
						class="error mb-2 text-danger"
						v-if="!$v.organization.name.required"
					>
						Field is required
					</div>
					<div
						class="error mb-2 text-danger"
						v-if="!$v.organization.name.minLength"
					>
						Name must have at least
						{{ $v.organization.name.$params.minLength.min }}
						letters.
					</div>

					<div class="form-group">
						<button
							type="submit"
							class="btn btn-primary btn-block rounded-0 text-uppercase"
						>
							Submit
						</button>
					</div>
					<div class="form-group">
						<p
							class="typo__p text-success"
							v-if="submitStatus === 'OK'"
						>
							Form submitted successfully.
						</p>
						<p
							class="typo__p text-danger"
							v-if="submitStatus === 'ERROR'"
						>
							Please fill the form correctly.
						</p>
						<p
							class="typo__p text-info"
							v-if="submitStatus === 'PENDING'"
						>
							Submitting . . .
						</p>
					</div>
				</b-form>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import { api } from "../../config";
import { required, minLength, between } from "vuelidate/lib/validators";
export default {
	data: () => ({
		organization: {
			name: ""
		},
		submitStatus: null
	}),
	async mounted() {
		await this.getOrganization();
	},
	methods: {
		async getOrganization() {
			let request = await fetch(`${api}/organizations`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`
				}
			});
			let response = await request.json();
			if (response !== null) {
				this.organization = response;
			}
		},
		async submit() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				// do your submit logic here
				this.submitStatus = "PENDING";
				await fetch(`${api}/organizations`, {
					method: "POST",
					body: JSON.stringify(this.organization),
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${this.$store.getters.token}`
					}
				});
				await this.getOrganization();
				this.submitStatus = "OK";
			}
		}
	},
	validations: {
		organization: {
			name: {
				required,
				minLength: minLength(3)
			}
		}
	}
};
</script>

<style></style>
