<template>
    <div>
        <b-row>
            <b-col cols="6" class="mt-2">
                <b-card :title="`Ticket #${ticket.ticketId}`">
                    <b-form @submit="submit" v-on:submit.prevent novalidate>
                        <div class="media border-bottom mb-2">
                            <div class="media-body">
                                <h5 class="mt-0">
                                    {{ message.name }}
                                    <b-badge variant="secondary">{{ message.websiteName }}</b-badge>
                                </h5>
                                <p>
                                    <code>{{ message.rawBody }}</code>
                                </p>
                                <p>
                                    <a
                                        href="javascript:void(0);"
                                        @click="viewConversation"
                                    >View Conversation</a>
                                </p>
                            </div>
                        </div>

                        <div
                            class="form-group"
                            :class="{
								'form-group--error': $v.ticket.subject.$error
							}"
                        >
                            <label class="form__label">Subject:</label>
                            <input
                                type="text"
                                class="form-control form__input"
                                v-model.trim="$v.ticket.subject.$model"
                            />
                        </div>

                        <div
                            class="error mb-2 text-danger"
                            v-if="!$v.ticket.subject.required"
                        >Field is required</div>

                        <div
                            class="form-group"
                            :class="{
								'form-group--error': $v.ticket.category.$error
							}"
                        >
                            <label class="form__label">Category:</label>
                            <b-form-select
                                class="form__input"
                                v-model.trim="$v.ticket.category.$model"
                                :options="categories"
                            ></b-form-select>
                        </div>

                        <div
                            class="error mb-2 text-danger"
                            v-if="!$v.ticket.category.required"
                        >Field is required</div>

                        <div
                            class="form-group"
                            :class="{
								'form-group--error': $v.ticket.group.$error
							}"
                        >
                            <label class="form__label">Agent Group:</label>
                            <b-form-select
                                class="form__input"
                                v-model.trim="$v.ticket.group.$model"
                                :options="groups"
                            ></b-form-select>
                        </div>

                        <div
                            class="error mb-2 text-danger"
                            v-if="!$v.ticket.group.required"
                        >Field is required</div>

                        <div
                            class="form-group"
                            :class="{
								'form-group--error': $v.ticket.priority.$error
							}"
                        >
                            <label class="form__label">Priority:</label>
                            <b-form-select
                                class="form__input"
                                v-model.trim="$v.ticket.priority.$model"
                                :options="priorities"
                            ></b-form-select>
                        </div>

                        <div
                            class="error mb-2 text-danger"
                            v-if="!$v.ticket.priority.required"
                        >Field is required</div>

                        <div class="form-group">
                            <label>Note:</label>
                            <vue-editor
                                v-model="ticket.note"
                                :editorToolbar="customToolbar"
                                @text-change="setTicketNote"
                            ></vue-editor>
                        </div>

                        <div class="form-group">
                            <label>Status:</label>
                            <b-form-select v-model="ticket.status" :options="status"></b-form-select>
                        </div>

                        <div class="form-group">
                            <button
                                type="submit"
                                class="btn btn-primary btn-block rounded-0 text-uppercase"
                                v-if="ticket.oldStatus !== 4"
                            >Update Ticket</button>
                        </div>

                        <div class="form-group">
                            <p
                                class="typo__p text-success"
                                v-if="submitStatus === 'OK'"
                            >Form submitted successfully.</p>
                            <p
                                class="typo__p text-danger"
                                v-if="submitStatus === 'ERROR'"
                            >Please fill the form correctly.</p>
                            <p
                                class="typo__p text-info"
                                v-if="submitStatus === 'PENDING'"
                            >Submitting . . .</p>
                        </div>
                    </b-form>
                </b-card>
            </b-col>
            <b-col cols="6" class="mt-2">
                <b-card v-for="(update, index) in updates" :key="index" class="mb-3">
                    <b-card-text>
                        <b-row class="border-bottom pb-2">
                            <b-col class="col-4 text-left">
                                <b v-html="`Update #${updates.length - index}`"></b>
                            </b-col>
                            <b-col
                                class="col-8 text-right"
                            >{{ update.agentName }} - {{ update.groupName }}</b-col>
                        </b-row>
                    </b-card-text>
                    <b-card-text>
                        <b-row class="border-bottom pb-2">
                            <b-col class="text-left">
                                {{
                                update.createdAt | readableDateTime
                                }}
                            </b-col>
                            <b-col class="text-right">
                                <span
                                    class="badge"
                                    v-bind:class="{
										'badge-danger': update.status === 1,
										'badge-primary': update.status === 2,
										'badge-warning': update.status === 3,
										'badge-success': update.status === 4
									}"
                                >{{ update.status | statusName }}</span>
                            </b-col>
                        </b-row>
                    </b-card-text>
                    <b-card-text>
                        <b-row
                            class="border-bottom pb-2"
                            v-show="update.categoryName !== categories[categories.findIndex(c => c.text === update.categoryName)].text"
                        >
                            <b-col class="text-left">{{ update.categoryName }}</b-col>
                            <b-col class="text-right">{{ update.priorityName }}</b-col>
                        </b-row>
                    </b-card-text>
                    <b-card-text v-show="update.note.length">
                        <div v-html="update.note" class="ticketUpdateContent"></div>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-modal
            v-model="conversationModal"
            modal-class="mh-100"
            centered
            hide-footer
            title="Message History"
        >
            <div class="d-block">
                <Conversation
                    :channelId="this.message.channel"
                    :position="this.message.uid"
                    :typingArea="false"
                    :contentHeight="`400px`"
                    v-if="conversationModal"
                />
            </div>
        </b-modal>
    </div>
</template>

<script>
import { api } from "../../config";
import Conversation from "../../components/Conversation";
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";
import moment from "moment";
import Noty from "noty";
import { required } from "vuelidate/lib/validators";
export default {
    data: () => ({
        message: {},
        ticket: {
            ticketId: "0000000000",
            subject: null,
            category: null,
            group: null,
            priority: null,
            note: "",
            rawNote: "",
            oldStatus: null
        },
        submitStatus: null,
        customToolbar: [
            ["bold", "italic", "underline"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["image"]
        ],
        categories: [],
        groups: [],
        priorities: [],
        updates: [],
        status: [
            { text: "Open", value: 1 },
            { text: "Pending", value: 2 },
            { text: "Spam", value: 3 },
            { text: "Solved", value: 4 }
        ],
        ready: false,
        conversationModal: false
    }),
    computed: {
        ...mapGetters({
            user: "user"
        })
    },
    async mounted() {
        await this.getTicket(this.$route.params.id);
        await this.getTicketCategories();
        await this.getTicketGroups();
        await this.getTicketPriorities();
    },
    methods: {
        async getTicket(ticketId) {
            let request = await fetch(`${api}/tickets/${ticketId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            this.ticket = await request.json();
            this.ticket.oldStatus = this.ticket.status;
            this.getTicketUpdates(ticketId);
            this.getMessage(this.ticket.message);
        },
        async getMessage(messageId) {
            let request = await fetch(
                `${api}/channels/messages/single/${messageId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`
                    }
                }
            );
            let response = await request.json();
            this.message = response.message;
            this.ready = true;
        },
        async getTicketUpdates(ticketId) {
            let request = await fetch(`${api}/tickets/updates/${ticketId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            this.updates = await request.json();
        },
        async getTicketCategories() {
            let request = await fetch(`${api}/tickets/categories`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.categories = [];
            response.forEach(category => {
                this.categories.push({
                    value: category.uid,
                    text: category.name
                });
            });
        },
        async getTicketGroups() {
            let request = await fetch(`${api}/users/groups`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.groups = [];
            response.forEach(group => {
                this.groups.push({
                    value: group.uid,
                    text: group.name
                });
            });
        },
        async getTicketPriorities() {
            let request = await fetch(`${api}/tickets/priorities`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                }
            });
            let response = await request.json();
            this.priorities = [];
            response.forEach(priority => {
                this.priorities.push({
                    value: priority.uid,
                    text: priority.name
                });
            });
        },
        setTicketNote() {
            this.ticket.rawNote = this.$options.filters.extractContent(
                this.ticket.note,
                true
            );
        },
        viewConversation() {
            this.conversationModal = true;
        },
        async submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitStatus = "ERROR";
            } else {
                this.submitStatus = "PENDING";

                let request = await fetch(`${api}/tickets/updates`, {
                    method: "POST",
                    body: JSON.stringify(this.ticket),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`
                    }
                });
                let response = await request.json();

                new Noty({
                    type: "success",
                    theme: "sunset",
                    layout: "bottomRight",
                    text: `Updated Ticket ID #${
                        this.ticket.ticketId
                    } from ${this.$options.filters.statusName(
                        this.ticket.oldStatus
                    )} to ${this.$options.filters.statusName(
                        this.ticket.status
                    )}`,
                    timeout: 5000
				}).show();
				
				this.ticket.oldStatus = this.ticket.status;
				this.getTicket(this.ticket.ticketId)
                this.getTicketUpdates(this.ticket.ticketId);

                this.submitStatus = "OK";
            }
        }
    },
    components: {
        Conversation,
        VueEditor
    },
    validations: {
        ticket: {
            subject: {
                required
            },
            category: {
                required
            },
            group: {
                required
            },
            priority: {
                required
            }
        }
    }
};
</script>
