<template>
	<div class="container">
		<div class="row">
			<div
				class="col-12 d-flex flex-row justify-content-center align-items-center"
				style="height: 600px;"
			>
				<img src="/img/spinner.gif" height="100" alt />
			</div>
		</div>
	</div>
</template>

<script>
import firebase from "firebase";
export default {
	mounted() {
		this.$store.dispatch("logout");
		firebase
			.auth()
			.signOut()
			.then(() => {
				setTimeout(() => {
					this.$router.push({
						path: "/login"
					});
				}, 3000);
			});
	}
};
</script>

<style></style>
