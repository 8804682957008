var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ready),expression:"ready"}],ref:"chatBox",staticClass:"px-4 py-5 bg-white overflow-auto",style:(`height:${_vm.contentHeight};`)},_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,staticClass:"media w-50",class:{
                '': message.user !== _vm.user.uid && message.userType !== 'bot',
                'ml-auto':
                    message.user === _vm.user.uid ||
                    message.userType === 'bot' ||
                    message.userType === 'agent',
            },attrs:{"id":message.uid,"data-user":`UID: ${message.uid} | Type: ${message.userType}`}},[_c('div',{staticClass:"media-body",class:{
                    'ml-3':
                        message.user !== _vm.user.uid &&
                        message.userType !== 'bot',
                    '':
                        message.user === _vm.user.uid ||
                        message.userType === 'bot' ||
                        message.userType === 'agent',
                }},[_c('div',{staticClass:"rounded py-2 px-3 mb-2",class:{
                        light:
                            message.uid !== _vm.position &&
                            message.user !== _vm.user.uid &&
                            message.userType !== 'bot' &&
                            message.userType !== 'agent',
                        'bg-primary':
                            message.uid !== _vm.position &&
                            (message.user === _vm.user.uid ||
                                message.userType === 'bot' ||
                                message.userType === 'agent'),
                        'bg-secondary': message.uid === _vm.position,
                    }},[_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.showMessageActions(message)}}},[_c('p',{staticClass:"text-small mb-0 text-left message-body",class:{
                                'text-muted':
                                    message.uid !== _vm.position &&
                                    message.user !== _vm.user.uid &&
                                    message.userType !== 'bot' &&
                                    message.userType !== 'agent',
                                'text-white':
                                    message.uid === _vm.position ||
                                    message.user === _vm.user.uid ||
                                    message.userType === 'bot' ||
                                    message.userType === 'agent',
                            }},[_vm._v(" "+_vm._s(message.rawBody)+" ")])])]),_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"small text-muted timestamp timeago",attrs:{"title":`${_vm.moment(message.createdAt).format(
                        'MMMM DD, YYYY hh:mm A'
                    )}${
                        message.hasOwnProperty('name') &&
                        message.userType !== 'customer'
                            ? ' - ' + message.name
                            : ''
                    }`,"placement":"bottom"}},[_c('vue-moments-ago',{attrs:{"prefix":"","suffix":"ago","date":message.createdAt}})],1)])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ready),expression:"!ready"}],staticClass:"row"},[_vm._m(0)]),(_vm.typingArea && _vm.presence.user === _vm.user.uid)?_c('form',{staticClass:"light mt-2",on:{"submit":[_vm.send,function($event){$event.preventDefault();}]}},[_c('div',{staticClass:"input-group light-border"},[_c('b-form-textarea',{staticClass:"form-control rounded-0 border-0 py-4",attrs:{"placeholder":"Type a message","aria-describedby":"button-addon2","rows":"3","max-rows":"6"},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}}),_vm._m(1)],1)]):_vm._e(),_c('b-modal',{attrs:{"centered":"","hide-footer":"","title":"Message Actions"},model:{value:(_vm.messageActionsModal),callback:function ($$v) {_vm.messageActionsModal=$$v},expression:"messageActionsModal"}},[_c('div',{staticClass:"d-block text-center"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.createTicket}},[_vm._v("Create Ticket")])],1)]),_c('b-modal',{ref:"chatPresenceModal",attrs:{"centered":"","title":"Chat Agent","ok-title":"Yes","cancel-title":"No"},on:{"ok":function($event){return _vm.arrive(_vm.channelId, true)},"cancel":function($event){return _vm.$refs.chatPresenceModal.hide()}}},[_c('div',{staticClass:"d-block text-center"},[_c('b',[_vm._v(_vm._s(this.presence.name))]),_vm._v(" is "),(_vm.currentAgentTyping)?_c('span',[_vm._v("currently active in ")]):_c('spanv',[_vm._v("viewing")]),_vm._v(" this conversation. "),_c('br'),_vm._v("Do you want to takeover? ")],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 d-flex flex-row justify-content-center align-items-center",staticStyle:{"height":"600px"}},[_c('img',{attrs:{"src":"/img/spinner.gif","height":"100","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-link",attrs:{"id":"button-addon2","size":"lg","type":"submit"}},[_c('i',{staticClass:"las la-paper-plane",staticStyle:{"font-size":"30px"}})])])
}]

export { render, staticRenderFns }